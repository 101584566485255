<template>
  <div>
    <div
      v-if="isLoading"
      class="card-body"
    >
      <loading-spinner
        size-class="fa-lg"
        class="inline-flex"
      />
      Loading...
    </div>
    <div
      v-if="!isLoading"
    >
      <div class="card-header conversation-content">
        <div class="flex justify-between">
          <div class="conversation-name">
            <h2>
              {{ conversation.name }}
            </h2>
          </div>
          <div class="mb-0 whitespace-nowrap">
            <dropdown-menu
              menu-wrapper-classes="card-xs"
              menu-button-classes="card-body"
              menu-dropdown-classes="right-0"
            >
              <template #menu-text>
                <i class="fas fa-cog" />
              </template>
              <dropdown-menu-item :click="openReportConversation">
                Report Conversation
              </dropdown-menu-item>
            </dropdown-menu>
          </div>
        </div>
        <div class="conversation-date">
          {{ formattedDate(conversation.occurred_at) }}
        </div>
        <div class="conversation-attendees flex flex-wrap gap-2">
          <div class="attendees-label">
            <h5>
              Attendees:
            </h5>
          </div>
          <div class="attendees-chips flex gap-2">
            <div
              v-for="attendee in conversation.attendees"
              :key="attendee.id"
            >
              <Chip :label="attendee.displayName"/>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="conversation-transcript">
          <p>
            {{ transcript }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <ReportConversationDialog ref="reportConversationDialog" />
</template>

<script setup>
import { toRefs, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { deepUnref } from 'vue-deepunref';
import { getErrorMessage } from '#lib/Errors';
import { errorToast } from '#ui/lib/toast';
import LoadingSpinner from '#ui/components/LoadingSpinner';
import ReportConversationDialog from '#features/conversations/ui/dialogs/ReportConversationDialog';
import DropdownMenu from '#ui/components/DropdownMenu';
import DropdownMenuItem from '#ui/components/DropdownMenuItem';
import Chip from 'primevue/chip';

const props = defineProps({
  /**
   * @type {Number} The conversation ID to display.
   */
  conversationId: {
    type: String,
    required: true,
  },

  /**
   * @type {Array} The rows of data to display.
   */
  conversation: {
    type: Object,
    required: true,
  },

  /**
   * @type {Number} The conversation ID to display.
   */
  transcript: {
    type: String,
    required: true,
  },

  /**
   * @type {Function} A function that can be used to manage the population and pagination of
   * the list.  If omitted, no search or pagination controls will be displayed.
   */
  loadConversation: {
    type: Function,
    default: null,
  },

  /**
   * @type {Boolean} Controls if the loading overlay is displayed.
   */
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const {
  conversation,
  transcript,
  loadConversation,
  isLoading,
  conversationId,
} = toRefs(props);

const reportConversationDialog = ref(null);

const store = useStore();

const openReportConversation = () => {
  reportConversationDialog.value.openDialog(store.state.Conversations.conversation);
};

const getConversation = async () => {
  try {
    await loadConversation.value({
      conversationId: Number(conversationId.value),
    });
  }
  catch (error) {
    errorToast(getErrorMessage(error));
  }
};

const formattedDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

onMounted(async () => {
  if (loadConversation.value) {
    await getConversation(deepUnref({ conversationId }));
  }
});
</script>

<style scoped>
.loading-overlay {
  @apply absolute top-0 right-0 bottom-0 left-0;
  @apply flex justify-center items-center;
  background-color:rgba(255, 255, 255, 0.75);
}
</style>
