<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    :header="`Report '${name}'`"
  >
    <form
      class="dialog-body"
      @submit.prevent="submit"
    >
      <text-area
        v-model="form.message"
        class="mb-4"
        input-id="message"
        label="Please insert your concern with this conversation"
      />
    </form>
    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('reportConversation')"
        :size-class="'fa-2x'"
      />
      <button
        @click="closeDialog"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        :disabled="submitButtonDisabled"
        @click="submit"
      >
        Report
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import isEmpty from 'lodash/isEmpty';
import { reactive, ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { getErrorMessage } from '#lib/Errors';
import { loadable } from 'vue-is-loading';
import { successToast, errorToast } from '#ui/lib/toast';
import TextArea from '#ui/components/TextArea';
import LoadingSpinner from '#ui/components/LoadingSpinner';

// Setup
const store = useStore();

const id = ref(0);
const name = ref(0);
const form = reactive({
  message: '',
});
// Open/close

const visible = ref(false);
const openDialog = (conversation) => {
  id.value = conversation.id;
  name.value = conversation.name;
  form.message = '';

  visible.value = true;
};
const closeDialog = () => {
  form.message = '';

  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// Submit Conversation Report

const submitButtonDisabled = computed(() => isEmpty(form.message));

const reportConversation = loadable(
  (values) => store.dispatch('Conversations/sendReport', values),
  'sendReport',
  getCurrentInstance(),
);

const submit = async () => {
  try {
    await reportConversation({ message: form.message, conversation_id: id.value, conversation_name: name.value });
    closeDialog();
    successToast('Report sent.  We\'ll get back to you ASAP!');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not send conversation report.'));
  }
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
