<template>
  <div class="lg:flex lg:flex-row lg:justify-between lg:items-start">
    <div class="card lg:w-2/3 mb-4 lg:mb-0 lg:mr-4">
      <ConversationDetail
        :conversation-id="conversationId"
        :conversation="currentConversation"
        :transcript="currentTranscript"
        :fields="conversationFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-conversation="getDetails"
        :is-loading="$isLoading('getDetailsConversation')"
        :actions="conversationActions"
        :empty-message="'No conversations'"
        class="w-full"
      />
    </div>

    <div
      class="lg:w-1/3 lg:ml-4"
    >
      <div class="flex flex-col">
        <div class="mb-4">
          <label
            class="block text-sm font-medium text-gray-700"
            for="analysisbatch"
          >
            Showing results for analysis batch:
            <select
              id="analysisbatch"
              v-model="selectedIndex"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option
                disabled
                value=""
              >
                Please select one
              </option>
              <option
                v-for="(analysisBatch, index) in currentAnalyzerBatches"
                :key="index"
                :value="index"
              >
                Analysis - {{ formattedDate(analysisBatch.created_at) }}
              </option>
            </select>
          </label>
        </div>
        <button
          class="btn-primary mb-4"
          :disabled="$isLoading('analyzingConversation')"
          @click="requestAnalysis"
        >
          ReAnalyze
        </button>
        <div v-if="!$isLoading('getDetailsConversation') && currentAnalyzerBatches[selectedIndex]">
          <div
            v-for="analyzer in currentAnalyzerBatches[selectedIndex].analyzers"
            :key="analyzer.id"
            class="card mb-4"
          >
            <h3 class="card-header">
              {{ analyzer.name }}
            </h3>
            <div
              class="card-body"
              v-html="analyzer.text"
            />
          </div>
          <div
            v-if="!currentAnalyzerBatches[selectedIndex].analyzed_at"
            class="card"
          >
            <div
              class="card-body"
            >
              <div
                v-if="currentAnalyzerBatches[selectedIndex].credit_limit_reached"
                class="card-body"
              >
                This analysis couldn't be run because you were out of credits for the month -
                <router-link to="/subscription">upgrade for more analysis credits.</router-link>
              </div>
              <div v-if="!currentAnalyzerBatches[selectedIndex].credit_limit_reached">
                <div
                  v-if="!currentAnalyzerBatches[selectedIndex].analysis_failed"
                  class="card-body"
                >
                  This analysis is still in progress - check back in a few minutes!
                </div>
                <div
                  v-if="currentAnalyzerBatches[selectedIndex].analysis_failed"
                  class="card-body"
                >
                  Sorry - this conversation could not be analyzed. No analysis credits were consumed. Please try uploading the transcript again. If this keeps happening, please contact support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import { successToast, errorToast } from '#ui/lib/toast';
import ConversationDetail from '#features/conversations/ui/components/ConversationDetail';
import { CONVERSATION_MODEL_FIELDS } from '#features/conversations/lib/models/ConversationModel';

const route = useRoute();
const conversationId = route.params.id;
// Setup

// Selected batch index
const selectedIndex = ref(0);

const store = useStore();
const currentPage = computed(() => store.state.Conversations.currentPage);
const currentConversation = computed(() => store.state.Conversations.conversation);
const currentTranscript = computed(() => store.state.Conversations.transcript);
const currentAnalyzerBatches = computed(() => store.state.Conversations.analyzerBatches);
const totalPages = computed(() => store.state.Conversations.totalPages);
const conversationFields = computed(() => omit(CONVERSATION_MODEL_FIELDS, 'id'));

// List navigation

const getDetails = loadable(
  (values) => store.dispatch('Conversations/getDetails', values),
  'getDetailsConversation',
  getCurrentInstance(),
);

const analyzeConversation = loadable(
  (values) => store.dispatch('Conversations/analyzeConversation', values),
  'analyzingConversation',
  getCurrentInstance(),
);

const formattedDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

const requestAnalysis = async () => {
  try {
    await analyzeConversation({ id: store.state.Conversations.conversation.id });
    selectedIndex.value = 0;
    successToast('New Analysis requested.');
  }
  catch (error) {
    errorToast('Sorry - we could not reanalyze conversation right now.');
  }
};

</script>
